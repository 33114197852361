<template>
    <v-card
        class="mx-auto"
        max-width="600"
        outlined
    >
        <v-list-item three-line>
            <v-list-item-content>
                <div class="mb-3 font-weight-bold header cardtext--text" >
                    {{ calculation}}
                </div>
                <v-list-item-title class="mb-1 font-weight-bold sub-header cardtext--text">
                    {{ subHeader }}
                </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="55"
            >
             <img :src="'./img/' + avatarImg">
            </v-list-item-avatar>
        </v-list-item>
         <v-card-text class="mt-md-7 card-body cardtext--text">{{ cardBody }}</v-card-text>
    </v-card>
</template>

<script>
export default {
  name: "CalculatorCard",
  props: [
      'calculation',
      'subHeader',
      'cardBody',
      'avatarImg'
      ],
  mounted() {
    this.show = true;
  },
};
</script>

<style>
    .v-card {
        min-height: 335px;
        padding: 15px;
    }

    .header {
        font-size: 28px !important;
    }
    .sub-header {
        font-size: 17px !important;
        line-height: 23px !important;
        overflow: visible !important;
        text-overflow: unset !important;
        white-space: normal !important;
    }

    .card-body {
        font-size: 16px !important;
        line-height: 23px !important;
        padding: 20px !important;
        padding-top: 30px !important;
        vertical-align: bottom !important;
    }

    @media only screen and (max-width: 959px) {
        .v-card {
            min-height: 200px;
        }
    }
</style>