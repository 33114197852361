import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: "#aa164a",
                secondary: "#171b34",
                accent: "#3D87E4",
                cardtext: "#111D30"
            }
        }
    }
});
