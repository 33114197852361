import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueMatomo from 'vue-matomo';

Vue.config.productionTip = false

Vue.use(
  VueRouter
)

const routes = [
  { path: '', component: App },
]

const router = new VueRouter({
   mode: 'history',
  routes
})

Vue.use(VueMatomo, {
  host: "https://digi-solutio.matomo.cloud/",
  siteId: 5,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: "https://potenzialrechner.cheftresor.de",
  domains: undefined,
  preInitActions: []
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
