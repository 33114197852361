<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import NumberUtil from './modules/utils/NumberUtil';

ChartJS.register(BarElement, CategoryScale, LinearScale, ChartDataLabels);

ChartJS.defaults.set('plugins.datalabels', {
    color: '#FFFFF',
    anchor: 'end',
    align: 'end',
    offset: 0,
    formatter: function(value) {
        let num = parseFloat(value);
        return NumberUtil.formatCurrency(num) + " €";
    },
    font: {
        size: 16
    }
});

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 300
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                },
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    display: false,
                }
            },
        },
        layout: {
            padding: 20
        },
        plugins: {
            legend: false,
            tooltip: {
                enabled: false
            }
        },
      }, 
      plugins: [ChartDataLabels]
    }
  },
  methods: {}
}
</script>

<style>
    canvas {
        margin: auto;
    }
</style>