<template>
  <div>
    <div class="calculator-wrapper">
      <v-container class="kalkulator" id="kalkulator">
         <v-row class="text-center mt-1 mb-10">
            <v-col cols="12">
              <img :src="'./img/' + cheftresorLogo"/>
            </v-col>
         </v-row>
          <v-row>
            <v-col cols="12" md="12" class="text-center">
              <h1 class="headline font-weight-bold mb-4" id="calculator-header">
                Potenzialrechner
              </h1>
              <h4 class="primary--text font-weight-bold mb-3">
                Was bringt mir das? Eine Kanzlei-Rechnung, die aufgeht!
              </h4>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <label class="calculator-label">Mandantenanzahl</label>
                <v-text-field
                  :value="mandanten"
                  @change.native="mandanten = $event.target.value"
                  outlined 
                  class="rounded-1 calculator-text-field" 
                  type="number"
                  @keypress="isIntNumber($event)"
                  v-on:blur="calculateLizenz"
                  @paste.prevent
                  >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label class="calculator-label">Ø-Aufwand pro Mandant / Monat</label>
              <v-text-field 
                :value="mandantArbeit"
                @change.native="mandantArbeit = $event.target.value"
                outlined 
                class="rounded-1 calculator-text-field aufwand-mandant"
                type="number"
                append-icon="h"
                @keypress="isIntNumber($event)"
                v-on:blur="mandantArbeit = setToMinMax(mandantArbeit, 3, 6)"
                @paste.prevent
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template v-slot:actions>
                      <v-icon class="panel-icon" color="primary">$expand</v-icon>
                  </template>
                  <span class="panel-header primary--text" color="primary">Detailberechnung</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                      <v-col cols="12" md="6">
                          <label class="calculator-label">Interner Stundensatz</label>
                          <v-text-field 
                            :value="satzIntern"
                            @change.native="satzIntern = $event.target.value"
                            outlined 
                            class="rounded-1 calculator-text-field" 
                            type="number"
                            append-icon="mdi-currency-eur"
                            @keypress="isIntNumber($event)"
                            v-on:blur="satzIntern = setToMinMax(satzIntern, 20, 60)"
                            @paste.prevent
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <label class="calculator-label">Externer Stundensatz</label>
                          <v-text-field 
                            :value="satzExtern"
                            @change.native="satzExtern = $event.target.value"
                            outlined 
                            class="rounded-1 calculator-text-field" 
                            type="number"
                            append-icon="mdi-currency-eur"
                            @keypress="isIntNumber($event)"
                            v-on:blur="satzExtern = setToMinMax(satzExtern, 60, 200)"
                            @paste.prevent
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <label class="calculator-label">Derzeitiger Lohnkonvertereinsatz in %</label>
                          <v-text-field 
                            :value="fibuVierDreiAnteil"
                            @change.native="fibuVierDreiAnteil = $event.target.value"
                            v-on="updateSettings()"
                            hide-details
                            outlined 
                            class="rounded-1 calculator-text-field" 
                            type="number"
                            @keypress="isIntNumber($event)"
                            v-on:blur="fibuVierDreiAnteil = setToMinMax(fibuVierDreiAnteil, 0, 100)"
                            @paste.prevent
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <label class="calculator-label">Anteil Bilanzen GB Jahresabschluss in %</label>
                          <v-text-field 
                            :value="lohnAutoAnteil"
                            @change.native="lohnAutoAnteil = $event.target.value"
                            v-on="updateSettings()"
                            hide-details
                            outlined 
                            class="rounded-1 calculator-text-field" 
                            type="number"
                            @keypress="isIntNumber($event)"
                            v-on:blur="lohnAutoAnteil = setToMinMax(lohnAutoAnteil, 0, 100)"
                            @paste.prevent
                          >
                          </v-text-field>
                        </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10">
                      <label class="calculator-label optional-label">Optionale Weiterberechnung der Lizenzen</label>
                    </v-col>
                    <v-col cols="2">
                      <v-switch
                        v-model="mandantBerechnung"
                        class="float-end optional-calc-switch"
                      >
                      </v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="optional-calc-col" col="12">
                        <v-text-field 
                          :value="mandantLizenzEinnahmen"
                          @change.native="mandantLizenzEinnahmen = parseFloat($event.target.value)"
                          :disabled="!mandantBerechnung"
                          hide-details
                          outlined 
                          class="rounded-1 calculator-text-field" 
                          type="number"
                          append-icon="mdi-currency-eur"
                          @keypress="isIntNumber($event)"
                          v-on:blur="mandantLizenzEinnahmen = setToMinMax(mandantLizenzEinnahmen, 19, 119)"
                          @paste.prevent
                        >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col class="text-center" cols="12">
              <h3>Kanzleinutzen</h3>
            </v-col>
            <v-col class="text-center" cols="12">
              <div class="d-inline-flex">
                <span class="pr-5">Monat</span>
                <v-switch class="chart-switch"
                    v-model="isChartYearly"
                >
                </v-switch>
                <span class="pl-5">Jahr</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="mr-md-5">
            <v-col class="chart-col text-center" cols="12" md="6" sm="6">
              <p class="chart-header">Mandant</p>
              <calculator-bar-chart
                :chartData="getTenantChartData()"
              ></calculator-bar-chart>
            </v-col>
            <v-col class="chart-col text-center" cols="12" md="6" sm="6">
              <p class="chart-header">Kanzlei</p>
              <calculator-bar-chart
                :chartData="getChancelleryChartData()"
              ></calculator-bar-chart>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="6">
                <div class="legend-box-dark float-right">
                  <span class="legend-text">Lizenzpreis</span>
                </div>
            </v-col>
            <v-col cols="6">
              <div class="legend-box-light float-left">
                  <span class="legend-text">Zeitersparnis in €</span>
                </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center mt-10 sub-chart-text" col="12">
              <h3 class="primary--text">Lorem ipsum Platzhalter</h3>
            </v-col>
          </v-row>
        </v-container>
  </div>
  
  <v-container class="prognose-container" fluid>
    <div class="container">
      <v-row class="ml-md-0 ml-2">
        <h4 class="font-weight-regular">PROGNOSE</h4>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="px-4">
          <calculator-card 
            :calculation="receiveSavingPerTenantPerYear()"
            subHeader="Einsparpotenzial pro Mandant pro Jahr"
            :cardBody="'Wir schätzen, dass Ihre monatliche Ersparnis pro Mandant bei ca. ' + receiveSavingPerTenantPerMonth() +' liegt. Im Jahr entstehen Ihnen dadurch ca. ' + receiveSavingPerTenantPerYear() + ' weniger Kosten.'"
            avatarImg="growth.svg"
          ></calculator-card>
        </v-col>
        <v-col cols="12" md="4" class="px-4">
          <calculator-card 
            :calculation="receiveTotalYearlyCostSaving()"
            subHeader="Einsparpotenzial gesamt pro Jahr"
            :cardBody="'Wir schätzen, dass Sie pro Jahr Zeit- und Kostenersparnisse von rund ' + receiveTotalYearlyCostSaving() + ' erzielen können, indem Sie mit Cheftresor Ihre manuelle Tätigkeit reduzieren und Aufgaben digitalisieren.'"
            avatarImg="euro.svg"
          ></calculator-card>
        </v-col>
        <v-col cols="12" md="4" class="px-4">
          <calculator-card 
            :calculation="receiveYearlyTenantTimeSavings()"
            subHeader="Zeitersparnis pro Mandant pro Jahr"
            :cardBody="'Wir schätzen, dass Sie pro Monat ca. ' + receiveMonthlyTenantTimeSavings() + ' Zeitersparnis durch den Cheftresor haben. Aufs Jahr verteilt sind das ' + receiveYearlyTenantTimeSavings() + '.'"
            avatarImg="pie-chart.svg"
          ></calculator-card>
        </v-col>
      </v-row>
      <v-row class="mt-5 ml-md-0 ml-2">
        <h4 class="font-weight-regular">KOSTEN & POTENZIALE</h4>
      </v-row>
       <v-row>
        <v-col cols="12" md="4" class="px-4">
          <calculator-card 
            calculation="14,90 €"
            subHeader="Lizenzkosten pro Mandant pro Monat"
            :cardBody="'Cheftresor kostet Sie im Pro-Tarif 14,90 € pro Mandant pro Monat. Den zusätzlichen Service können Sie direkt an Ihren Mandanten weiterberechnen.'"
            avatarImg="save-money.svg"
          ></calculator-card>
        </v-col>
         <v-col cols="12" md="4" class="px-4">
          <calculator-card 
            :calculation="receiveYearlyCompletePotential()"
            subHeader="Potenzial für Beratungsumsatz"
            :cardBody="'Wir schätzen, dass Sie pro Jahr Potenzial für Beratungsumsatz und bezahlten Mandantenservice in Höhe von ' + receiveYearlyCompletePotential() + ' haben.'"
            avatarImg="earnings.svg"
          ></calculator-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
  </div>
</template>

<script>
import CalculatorCard from "./CalculatorCard.vue";
import CalculatorBarChart from "./CalculatorBarChart.vue";
import NumberUtil from "./modules/utils/NumberUtil";

export default {
    name: "Kalkulator",
    data: () => ({
        cheftresorLogo: 'cheftresor_logo.svg',
        besterTarif: "Pro",
        mandanten: 30,
        mandantenSteps: 1,
        satzExtern: 110,
        satzIntern: 35,
        mandantArbeit: 4,
        detailBerechnung: false,
        lohnAutoAnteil: 60,
        fibuVierDreiAnteil: 50,
        lohnMax: 0.25,
        lohnMin: 0.1,
        fibuMax: 0.4,
        fibuMin: 0.15,
        mandantBerechnung: false,
        mandantLizenzEinnahmen: 30,
        lizenzStarter: {
            monthly: 299,
            mandantenInkl: 10,
            onboarding: 2499,
            additional: 25,
        },
        lizenzPower: {
            monthly: 899,
            mandantenInkl: 40,
            onboarding: 4999,
            additional: 22,
        },
        lizenzPro: {
            monthly: 399,
            mandantenInkl: 50,
            onboarding: 6499,
            additional: 7.98,
        },
        zeitErsparnisFaktor: 0.45,
        settings: {
            lohnActive: true,
            lohnAutoAnteil: 50,
            fibuVierDreiAnteil: 50,
        },
        kostenproMandant: 9.975,
        isChartYearly: false,
        licensePrice: 14.9
    }),
    methods: {
        isIntNumber(evt) {
            const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
            const keyPressed = evt.key;

            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault();
            }
        },
        calculateLizenz() {
            this.mandanten = this.setToMinMax(this.mandanten, 10, 200);

            let i = this.mandanten;
            let best;
            let procost;

            procost = this.lizenzPro.monthly / this.mandanten;
            best = procost
            this.besterTarif = "Pro"
            this.kostenproMandant = best;
            
            if (i > 50) {
                procost =
                    (this.lizenzPro.monthly +
                        this.lizenzPro.additional *
                            (this.mandanten - this.lizenzPro.mandantenInkl)) /
                        this.mandanten;
                this.kostenproMandant = procost;
                this.besterTarif = "Pro";
            }
        },
        setToMinMax(val, min, max) {
          if (!val || val < min) {
            return min;
          } else if (val > max) {
            return max;
          } else {
            return val;
          }
        },
        updateSettings() {
            this.zeitErsparnisFaktor =
                (this.lohnMin * this.lohnAutoAnteil +
                    this.lohnMax * (100 - this.lohnAutoAnteil)) /
                    100 +
                    (this.fibuMin * this.fibuVierDreiAnteil +
                        this.fibuMax * (100 - this.fibuVierDreiAnteil)) /
                        100;
        },      
        receiveSavingPerTenantPerMonthAsNum() {
          return this.mandantArbeit * this.zeitErsparnisFaktor * this.satzIntern -
                    this.kostenproMandant +
                    (this.mandantBerechnung ? this.mandantLizenzEinnahmen : 0);
        },
        receiveSavingPerTenantPerMonth() {
          return NumberUtil.formatCurrency(this.receiveSavingPerTenantPerMonthAsNum()) + " €"
        },
        receiveSavingPerTenantPerYear() {
          return NumberUtil.formatCurrency(
                  (
                    this.mandantArbeit * this.zeitErsparnisFaktor * this.satzIntern -
                    this.kostenproMandant +
                    (this.mandantBerechnung ? this.mandantLizenzEinnahmen : 0)
                  ).toFixed(2) * 12
                ) + " €"
        },
        receiveTotalMonthlyCostSaving() {
          return NumberUtil.formatCurrency2(
                this.mandanten *
                  (this.mandantArbeit * this.zeitErsparnisFaktor * this.satzIntern -
                    this.kostenproMandant +
                    (this.mandantBerechnung ? this.mandantLizenzEinnahmen : 0))
              ) + " €"
        },
        receiveTotalYearlyCostSaving() {
          return NumberUtil.formatCurrency(
                this.mandanten *
                  (this.mandantArbeit * this.zeitErsparnisFaktor * this.satzIntern -
                    this.kostenproMandant +
                    (this.mandantBerechnung ? this.mandantLizenzEinnahmen : 0)) * 12
              ) + " €"
        },
        receiveMonthlyTenantTimeSavings() {
          return NumberUtil.formatCurrency(this.mandantArbeit * this.zeitErsparnisFaktor) + " h"
        },
        receiveYearlyTenantTimeSavings() {
          return NumberUtil.formatCurrency(
                  (this.mandantArbeit * this.zeitErsparnisFaktor).toFixed(2) * 12
                ) + " h"
        },
        receiveMonthlyCompletePotential() {
          return NumberUtil.formatCurrency2(
                  this.mandanten *
                    (this.mandantArbeit * this.zeitErsparnisFaktor * this.satzExtern -
                      this.kostenproMandant +
                      (this.mandantBerechnung ? this.mandantLizenzEinnahmen : 0))
                ) + " €"
        },
        receiveYearlyCompletePotential() {
          return NumberUtil.formatCurrency2(
                  this.mandanten *
                    (this.mandantArbeit * this.zeitErsparnisFaktor * this.satzExtern -
                      this.kostenproMandant +
                      (this.mandantBerechnung ? this.mandantLizenzEinnahmen : 0)) *
                    12
                ) + " €"
        },
        getTenantChartData() {
          let firstBar = this.licensePrice;
          let secondBar = this.receiveSavingPerTenantPerMonthAsNum();

          return this.receiveCalculatedDataset(firstBar, secondBar);
        },
        getChancelleryChartData() {
          let firstBar = this.licensePrice * this.mandanten;
          let secondBar = this.receiveSavingPerTenantPerMonthAsNum() * this.mandanten;

          return this.receiveCalculatedDataset(firstBar, secondBar);
        },
        receiveCalculatedDataset(firstBar, secondBar) {
          if (this.isChartYearly) {
            firstBar = firstBar * 12;
            secondBar = secondBar * 12;
          }

         return {
              plugins: [{}],
              labels: [""],
              datasets: [
                {
                   data: [firstBar.toFixed(2)],
                   backgroundColor: '#1D958E',
                   borderColor: "transparent",
                   barThickness: 100, 
                   borderWidth: 15
                },
                { 
                   data: [secondBar.toFixed(2)],
                   backgroundColor: '#34BD7B',
                   borderColor: "transparent",
                   barThickness: 100, 
                   borderWidth: 15
                },
              ]
          }
        }
    },
    components: { CalculatorCard, CalculatorBarChart }
};
</script>

<style lang="scss">

.calculator-wrapper {
    margin: auto;

   @media (min-width: 960px) { 
    width: 35%;
    max-width: 600px;
    min-width: 480px;
  }

  @media (max-width: 959px) { 
    padding: 0 30px;
    width: auto;
    min-width: none;
    max-width: none;
  }

  @media (max-width: 481) { 
    padding: 0;
    width: unset;
    min-width: 40px !important;
    max-width: unset !important;
  }
}

#calculator-header {
  font-size: 35px !important;
}

.calculator-label {
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 1.5;
}

.calculator-text-field {
  height: 52px !important;
  font-size: 14px !important;
  line-height: 1.2 !important;
}

.calculator-text-field .v-icon {
  font-size: 18px !important;
}

.sub-input-text {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);
}

.aufwand-mandant .v-icon {
  font-style: normal;
}

.panel-icon {
      order: 0;
} 

.panel-header {
    order: 1;
}

.v-text-field {
    font-size: 16px !important;
}

.v-expansion-panel-content__wrap {
    padding: 0 10px 16px !important;
}

.v-expansion-panel::before  {
  box-shadow: none !important;
}

.v-expansion-panel-header i {
  transform: rotate(-90deg);
  margin-left: -20px;
}

.v-expansion-panel-header--active i {
  transform: rotate(0deg) !important;
}

.kalkulator .v-slider__thumb-label {
  font-size: 1rem;
}

.optional-calc-switch {
  margin-top: -5px !important;
}

.optional-calc-col {
  margin-top: -35px !important;
}

.zahlen {
  font-size: 110%;
}

.row {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.chart-switch {
  margin-top: -3px !important;
}

.sub-chart-text {
  margin-bottom: 70px;
}

.legend-box-dark {
  height: 35px;
  border-bottom: 1px solid $darker-chart-green;
  border-left: 10px solid $darker-chart-green;
  padding-left: 8px;

  @media (min-width: 482) { 
    width: 180px;
  }
}

.legend-box-light {
  height: 35px;
  border-bottom: 1px solid $lighter-chart-green;
  border-left: 10px solid $lighter-chart-green;
  padding-left: 8px;

  @media screen and (min-width: 482px) {
    width: 180px;
  }
}

.legend-text {
  font-size: 18px;

    @media screen and (max-width: 430px) {
     font-size: 13px !important;
    }
}

.optional-label {
  @media screen and (max-width: 375px) {
     font-size: 10px !important;
  }
}

.chart-header {
  padding-right: 45%;
}

.rowborder {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px 15px;
}
#kalkulator {
  padding: 40px 10px;
}
.subslider {
  height: 18px;
  font-size: 18px;
  padding: 10px 0 0 16px;
  margin-bottom: -12px;
}
.slider_c {
  position: relative;
  min-height: 70px;
}
.slider_c .v-slider {
  position: relative;
  bottom: -28px;
}
.valslider {
  position: absolute;
  right: 20px;
  bottom: 30px;
  height: 18px;
  font-size: 18px;
  font-weight: bold;
  padding: 0 0 0 16px;
}

.prognose-container {
  width: 100%;
  background-color: #EAEEF3; 
  padding: 90px 0 !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield;
}
</style>