<template>
  <v-app>
    <v-main>
      <Kalkulator />
      <v-spacer></v-spacer>
    </v-main>
    <v-footer dark padless color="primary" v-if="isFooterView()">
      <v-container>
        <v-row>
          <v-col class="text-center text-sm-left" cols="12" sm="5">
            &copy;{{ new Date().getFullYear() }} - <strong>Cheftresor</strong>
          </v-col>
          <v-col class="text-center text-sm-right" cols="12" sm="7">
            <a class="f_legal" href="https://www.cheftresor.de/impressum">Impressum</a>
            <a class="f_legal" href="https://www.cheftresor.de/datenschutz">Datenschutz</a>
            <a class="f_legal" href="https://www.cheftresor.de/agb">AGB</a>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import Kalkulator from "./components/Kalkulator";

export default {
  name: "App",

  components: {
    Kalkulator
  },

   mounted() {
      this.implementCookieFromCdn();
    },

  data: () => ({
    withFooter: true
  }),
  methods: {
    isFooterView() {
       return !(this.$route.query.withFooter == "false");
    },
    implementCookieFromCdn() {
      let cookieCdn = document.createElement('script')
      cookieCdn.setAttribute('charset', 'UTF-8');
      cookieCdn.setAttribute('src', '//cdn.cookie-script.com/s/d3d88fb9b510113b856e7aa937bf91d1.js');
      document.head.appendChild(cookieCdn);
    }
  },
};
</script>
<style lang="sass">
@import '../node_modules/typeface-fira-sans/index.css'
</style>
<style scoped>

.f_legal{
  color: #fff;
  padding-left: 10px;
}
</style>
